<template>
  <div>
    <v-btn :small="small" @click.stop="connect(party)" v-show="partyStatusService.isOnNetwork(party)" color="primary">
      Connect</v-btn
    >
    <v-btn :small="small" v-show="partyStatusService.isNotOnNetwork(party)" @click.stop="invite(party)"> Invite</v-btn>
    <span v-show="partyStatusService.isDisconnected(party)">
      <v-chip class="pa-4" color="white" text-color="black">
        <v-icon left> mdi-link-off </v-icon>
        Disconnected
      </v-chip>
    </span>
    <span v-show="partyStatusService.isConnected(party)"
      @mouseover="isConnectHover = true"
      @mouseleave="isConnectHover = false">
      <v-chip class="pa-4" color="green" text-color="white" v-if="!isConnectHover">
        <v-icon left> mdi-handshake-outline </v-icon>
        Connected
      </v-chip>
      <v-btn :small="small" v-if="isConnectHover" @click.stop="disconnect(party)"> Disconnect</v-btn>
    </span>
    <span v-show="partyStatusService.IsConnecting(party)">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip class="pa-4" v-bind="attrs" v-on="on">
            <v-icon left> mdi-handshake-outline </v-icon>
            Waiting for other connection to accept
          </v-chip>
        </template>
        <span>The connection will be finalized when the other connection accepts the invite</span>
      </v-tooltip>
    </span>
    <span v-show="partyStatusService.IsInviteSent(party)" @mouseover="isHover = true" @mouseleave="isHover = false">
      <v-chip class="pa-4" v-if="!isHover">
        <v-icon left> mdi-email-open-outline </v-icon>
        Invite sent
      </v-chip>
      <v-btn :small="small" v-if="isHover" @click.stop="resendInvite(party)"> Resend Invite</v-btn>
    </span>
    <span
      v-show="partyStatusService.isInviteExpired(party)"
      @mouseover="isResendInviteHover = true"
      @mouseleave="isResendInviteHover = false"
    >
      <v-chip class="pa-4" color="#A9A9A9" text-color="white" v-if="!isResendInviteHover">
        <v-icon left> mdi-lock-clock </v-icon>
        Invite Expired
      </v-chip>
      <v-btn :small="small" v-if="isResendInviteHover" @click.stop="resendInvite(party)"> Resend Invite</v-btn>
    </span>
    <span
      v-show="partyStatusService.isConnectionExpired(party)"
      @mouseover="isResendConnectHover = true"
      @mouseleave="isResendConnectHover = false"
    >
      <v-chip class="pa-4" color="#A9A9A9" text-color="white" v-if="!isResendConnectHover">
        <v-icon left> mdi-lock-clock </v-icon>
        Connection request expired
      </v-chip>
      <v-btn :small="small" v-if="isResendConnectHover" @click.stop="reconnect(party)">
        Resend connection request
      </v-btn>
    </span>
  </div>
</template>
<script>
import partyService from "@/services/partyService.js";
import partyStatusService from "@/services/partyStatusService.js";
import validationService from "@/services/validationService.js";
import invitationService from "@/services/invitationService";

const requiredRule = [v => (!!v && validationService.isvalidEmail(v)) || "Must be a valid e-mail"];

export default {
  props: {
    party: Object,
    small: Boolean,
    callback: Function, // Called when action is complete (invite or connect)
  },
  data() {
    return {
      partyStatusService,
      isHover: false,
      isResendInviteHover: false,
      isConnectHover: false,
      isResendConnectHover: false,
    };
  },
  methods: {
    async showConfirmDialog() {
      let answer = await this.$root.$confirmDialog.open(
        "Accept",
        "By clicking continue, you agree that you have done any applicable due diligence specific to your company policies relevant to accepting documents from outside connections",
        "Continue"
      );

      return answer;
    },
    async showConfirmDisconnectDialog() {
      let answer = await this.$root.$confirmDialog.open(
        "Accept",
        "Are you sure you want to disconnect?",
        "Continue"
      );

      return answer;
    },
    async resendInvite(party) {
      let email = await this.$root.$inputDialog.open("Do you want to change the email?", "E-mail", requiredRule, party.emails[0]);
      if (!email) return;
      if (email !== party.emails[0]) {
        let fullParty = await partyService.get(party.id);
        fullParty.emails.unshift(email);
        await partyService.update(fullParty);
        party.emailsAsString = email;
      }
      await invitationService.resend(party.id, email);
      party.isInviteSent = true;
    },
    async invite(party) {
      try {
        let fullParty = await partyService.get(party.id);
        var emailAddress = fullParty.emails[0];
        var email = await this.$root.$inputDialog.open("Where should we send the invite?", "E-mail", requiredRule, emailAddress);
        if (!email) {
          return;
        }
        if(fullParty.emails[0] !== email){
          fullParty.emails = [email];
          await partyService.update(fullParty);            
        }

        party.emailsAsString = email;

        let answer = await this.showConfirmDialog();

        if (!answer) return;

        party.isInviteSent = true;
        await partyService.invite(party.id);
      } catch (err) {
        this.$root.$snackbar.exception(err);
        party.isInviteSent = false;
      }
      if (this.callback) this.callback();
    },

    async connect(party) {
      let answer = await this.showConfirmDialog();

      if (answer) {
        party.isInviteSent = true;
        await partyService.connect(party.id);
        if (this.callback) this.callback();
      }
    },
    async reconnect(party) {
      let answer = await this.showConfirmDialog();

      if (answer) {
        party.isInviteSent = true;
        await partyService.reconnect(party.id);
        party.partyStaus = "connecting";
        if (this.callback) this.callback();
      }
    },
    async disconnect(party) {
      let answer = await this.showConfirmDisconnectDialog();

      if (answer) {
        party.isInviteSent = true;
        await partyService.disconnect(party.id, true, true);
        party.partyStaus = "disconnect";
        if (this.callback) this.callback();
      }
    },
  },
};
</script>
